import React, { Component } from 'react';
// import CardData from './cardData.json'
import cardData_temp from './cardData_temp.json'

class Jumbotron extends Component {
    state = {  } 
    render() { 
        const { detail, title } = this.props;
        return (
            <div>
                 <div class="jumbotron jumbotron-fluid">
                <div class="container">
                <h1 class="display-4">{title}</h1>
                <p class="lead">{detail}</p>
                </div>
                </div>
            </div> 
        );
    }
}
 
export default Jumbotron;