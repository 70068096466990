import React, { Component } from "react";
import './styleBhakti.css';


class Yt_card extends Component {
  state = {};
  render() {
    const { videoId, title, artist, description } = this.props;
    return (
      <div>
        {/* <section className="youtube" />
        <br />
        <div className="youtube-neon-logo">
          <img src="https://lasbibas.uxdir.com/files/youtube-neon.png"  alt="YouTube Neon Logo" />
        </div> */}
        <div className="youtube-feed">
          <div className="youtube-card">
            <div className="video-cover">
              <img src={'https://img.youtube.com/vi/'+videoId+'/0.jpg'}  alt={title}/>
            </div>
            <a href={"https://youtu.be/"+videoId} target="_blank" rel="noopener noreferrer">
              <div className="overlay"></div>
            </a>
            <div className="video-data">
              <div className="controls">
                <div className="dial">►</div>
              </div>
              <div className="data">
                <h1>{title}</h1>
                <h2>{artist}</h2>
                <h3>{description}</h3>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Yt_card;
