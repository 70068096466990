import React, { Component } from 'react';
import { Link } from 'react-router-dom'
import './cardStyle.css'
import CardDetails from './CardDetails.js';

class Card extends Component {
    state = { }
    render() {
        const { imageSrc, title, description, cardId } = this.props;
        return (
            <div>
            <div className="Main-card">
                <img className = "img-for-Main-card"src={imageSrc} alt="" />
                <div className="info-for-Main-card">
                    <h1 className='h1-for-Main-Card'>{title}</h1>
                    <p className='p-for-Main-Card'>{description}</p>
                    <Link to={'CardDetails/' +cardId}>
                    <button className='button-for-Main-Card'>Read More</button>
                    </Link>
                </div>
            </div>
            </div>
        );
    }
}

export default Card;


/*card image address link*//*
https://lh3.googleusercontent.com/d/
/*card image address link*/