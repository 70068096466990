import React, { Component } from 'react';
import './Style.css';  // Import the CSS file

class SudarshanChakra
 extends Component {
    state = {  } 
    render() { 
        return (
            <div>
                <center><p>SudarshanChakra</p></center>
                <center>
                <img
                  id="logo"  // Remove the "#" from id
                  src="https://s10.gifyu.com/images/SD60l.png"
                  className="spin logo"  // Use className instead of class
          />
        </center>
            </div>
        );
    }
}
 
export default SudarshanChakra
;