import React, { Component } from 'react';
import VyasJi_card from './VyasJi_card';
import VyasJi_Json from './VyasJi.json'
class VyasJi_Book extends Component {
    state = {  } 
    componentDidMount() {
        //FOr some part of card
        const fontAwesomeLink = document.createElement('link');
    fontAwesomeLink.href = 'https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css';
    fontAwesomeLink.rel = 'stylesheet';
    document.head.appendChild(fontAwesomeLink);
        //For bg
        const bootstrapLink = document.createElement('link');
    bootstrapLink.href = 'https://cdnjs.cloudflare.com/ajax/libs/twitter-bootstrap/4.1.2/css/bootstrap.min.css';
    bootstrapLink.rel = 'stylesheet';
    document.head.appendChild(bootstrapLink);

        const tailwindLink = document.createElement('link');
    tailwindLink.href = 'https://unpkg.com/tailwindcss@2/dist/tailwind.min.css';
    tailwindLink.rel = 'stylesheet';
    document.head.appendChild(tailwindLink);
      }
    render() { 
        return (
            <div>
            <p>VyasJi_Book</p>
            <div class="container">
    <div class="row">
      <div class="col-12 col-sm-6 col-md-4 col-lg-3">
        </div>
            <div className="row">
      {VyasJi_Json.map((item, index) => (
        <div className='col-3' key={index}>
          <VyasJi_card
            imageSrc={item.imageSrc}
            name={item.name}
            title={item.title}
            facebookLink={item.facebookLink}
            youtubeLink={item.youtubeLink}
            twitterLink={item.twitterLink}
            instagramLink={item.instagramLink}
          />
        </div>
      ))}
    </div>
    </div>
    
    </div>
            </div>
        );
    }
}
 
export default VyasJi_Book;