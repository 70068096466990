import React, { Component } from 'react';
import './YtCardPlayerStyle.scss';

class YtCardPlayer extends Component {
  state = {
    videoIsOpen: false
  };
  
  componentDidMount() {
    // Load the YouTube IFrame API script
    const script = document.createElement('script');
    script.src = "https://www.youtube.com/iframe_api";
    document.body.appendChild(script);

    document.body.classList.add('body-for-YtCardPlayer');
    
    // Setup YouTube API callback
    window.onYouTubeIframeAPIReady = this.createPlayer;
    

    // Set background image dynamically
    const { backgroundImage } = this.props;
    if (backgroundImage) {
      document.querySelector('.YtCardPlayer').style.setProperty('--background-image', `url(${backgroundImage})`);
    }
  }
  
  componentWillUnmount() {
    document.body.classList.remove('body-for-YtCardPlayer');
    if (this.cleanup) {
      this.cleanup();
    }
}

  createPlayer = () => {
    this.player = new window.YT.Player('video', {
      events: {
        'onReady': this.onPlayerReady
      }
    });
  };

  onPlayerReady = () => {
    // Ensure the video does not autoplay
    this.player.pauseVideo();
  };

  handlePlayClick = () => {
    this.setState({ videoIsOpen: true }, () => {
      if (this.player) {
        this.player.playVideo();
      }
    });
  };

  handleMouseMove = (e) => {
    const YtCardPlayer = e.currentTarget;
    const rect = YtCardPlayer.getBoundingClientRect();
    const x = e.clientX - rect.left;
    const y = e.clientY - rect.top;

    YtCardPlayer.style.setProperty('--x', `${x}px`);
    YtCardPlayer.style.setProperty('--y', `${y}px`);
  };

  render() {
    const { videoIsOpen } = this.state;
    const { videoLink, videoParams, backgroundImage } = this.props; // Destructure videoLink and videoParams props

    // Construct the full iframe src using the videoLink and additional parameters
    const iframeSrc = `${videoLink}?${videoParams}&enablejsapi=1`;

    return (
      <div>
        <center>
        <p>Hindi</p>
        <div 
          className={`YtCardPlayer ${videoIsOpen ? 'video-is-open' : ''}`} 
          onMouseMove={this.handleMouseMove}
        >
          <div className="YtCardPlayer-play" onClick={this.handlePlayClick}></div>
          <div className="YtCardPlayer-video">
            <iframe
              // loading="lazy"
              id="video"
              src={iframeSrc} // Use the constructed iframeSrc
              backgroundImage= {this.backgroundImage}
              title="YouTube video player"
              allowFullScreen
              // allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            ></iframe>
          </div>
        </div>
        </center>
      </div>
    );
  }
}

export default YtCardPlayer;
