import React, { Component } from 'react';
import Card from './Card.js'
import OmNamoBhagwateVasudevay from './images/OmNamoBhagwateVasudevay.png'
import Image3 from './images/image__3.png';
import Untitled from './images/Untitled (1).png';
import SudarshanChakra from './SudarshanChakra.js';
import cardData from './cardData.json'
import './styleBhakti.css'

class Home extends Component {
    state = { 
        movies:[]
     } 
    render() { 
        return (
            <div>
            <center><img src = {OmNamoBhagwateVasudevay}/></center>
            <center><img  src = {Image3}/></center> 
            <center><img src = {Untitled}/></center>
            <SudarshanChakra/>
            <h3>Pillars Development</h3>
            <br/><br/>
            <br/><br/>
            <br/><br/>
            <br/><br/>
            <br/><br/>
            <br/><br/>
            <body className="body-for-Main-card ">
               <main className='main-for-Main-card'>
            {cardData.map((item, index) => (
                <Card
                imageSrc={item.imageSrc}
                title={item.title}
                description={item.description}
                cardId={item.cardId}/>
                ))}
                </main>
                
                </body>
            </div>
        );
    }
}
 
export default Home;