import React from 'react';
import ReactDOM from 'react-dom'
import App from './App'

ReactDOM.render(
     
    <App/>
    
    ,document.querySelector('#root'));




// API url : https://api.themoviedb.org/3/movie/top_rated?api_key=%3C%3Capi_key%3E%3E&language=en-US&page=1

// API: 2fa664cd84c88b80a75821c536a1bd11