import React, { useEffect, useRef } from 'react';
import { Bokeh1Background } from 'https://cdn.jsdelivr.net/npm/threejs-components@0.0.2/build/backgrounds/bokeh1.cdn.min.js';
import Card from './Card';
import cardData from './cardData.json';
// import './auraHomePageStyle.css'

const BokehBackground = () => {
  const canvasRef = useRef(null);

  useEffect(() => {
    const bokeh1Background = Bokeh1Background(canvasRef.current);
    bokeh1Background.loadMap('https://cdn.jsdelivr.net/npm/threejs-components@0.0.2/build/assets/bokeh-particles2.png');
    bokeh1Background.setColors([0x6d4862, 0xfd826c, 0x22ccc1]);

    const handleClick = () => {
      bokeh1Background.setColors([0xffffff * Math.random(), 0xffffff * Math.random(), 0xffffff * Math.random()]);
    };

    document.body.addEventListener('click', handleClick);

    return () => {
      document.body.removeEventListener('click', handleClick);
    };
  }, []);

  return (
    <div className="bokeh-bg" style={{ position: 'relative', minHeight: '100vh', overflow: 'auto' }}>
      <canvas
        id="webgl-canvas"
        ref={canvasRef}
        style={{
          position: 'fixed', // Fixed positioning to stay in the background
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          zIndex: -1 // Ensure it stays in the background
        }}
      />
      <div id="app">
        <div id="hero">
          <h1>Ongoing Work</h1>
        </div>
        </div>
        <div className="body-for-Main-card">
          <main className="main-for-Main-card">
            {/* {cardData.map((item, index) => (
              <Card
                key={index}
                imageSrc={item.imageSrc}
                title={item.title}
                description={item.description}
              />
            ))} */}
            {/* Temp Card Start */}
            <div className="Main-card">
  <img className='img-for-Main-card'
    src="https://user-images.githubusercontent.com/13468728/230662929-3ad798fd-5d35-43c7-9867-5a1302153bac.jpg" 
    alt="" 
  />
  <div className="info-for-Main-card">
    <h1>Fresh Flavors</h1>
    <p>
      The farm isn't just a place to work; it's like a big, green friend that provides delicious fruits and vegetables. You enjoy meals made from fresh, local goodies. It's all about keeping things simple, tasty, and connected to the land.
    </p>
    <button className='button-for-Main-Card'>Read More</button>
  </div>
</div>

<div className="Main-card">
  <img className='img-for-Main-card'
    src="https://user-images.githubusercontent.com/13468728/230662939-40a60e43-2d49-4cc1-9bb2-bed6da083e88.jpg" 
    alt="" 
  />
  <div className="info-for-Main-card">
    <h1>Whispers of Nature</h1>
    <p>
      In the countryside, plants are like the storytellers of the landscape, each one with its own tale to tell. Simple pleasures come from watching plants grow and change with the seasons. The beauty of plants is a constant reminder of the rhythm of life in the country.
    </p>
    <button className='button-for-Main-Card'>Read More</button>
  </div>
</div>

<div className="Main-card">
  <img className='img-for-Main-card'
    src="https://user-images.githubusercontent.com/13468728/230662955-9deccd97-6e8f-4ec0-8257-50f6627f5663.jpg" 
    alt="" 
  />
  <div className="info-for-Main-card">
    <h1>Countryside Harmony</h1>
    <p>
      Life in the countryside follows a rhythmic routine. When the sun comes up, farmers take care of their fields and they are growing rows of crops that dance in the breeze. The good things that come from the land are a happy celebration of how much nature gives us.
    </p>
    <button className='button-for-Main-Card'>Read More</button>
  </div>
</div>
{/* Temp Card end */}
          </main>
      </div>
      <h1 color = "#ffffff">Email Address: chaiirusmickey@gmail.com</h1>

    </div>
  );
};

export default BokehBackground;
