import React, { Component } from 'react';
import Yt_card from './Yt_card';
import playlistData from "./playlistData.json";

class Bhakti_Songs extends Component {
    componentDidMount() {
        document.body.classList.add('bhakti-songs-body');
        const link = document.createElement('link');
        link.rel = 'stylesheet';
        link.href = 'https://cdn.jsdelivr.net/npm/bootstrap@4.5.3/dist/css/bootstrap.min.css';
        link.integrity = 'sha384-TX8t27EcRE3e/ihU7zmQxVncDAy5uIKz4rEkgIXeMed4M0jlfIDPvg6uqKI2xXr2';
        link.crossOrigin = 'anonymous';
        document.head.appendChild(link);
        this.cleanup = () => {
          document.head.removeChild(link);
        };
    }

    componentWillUnmount() {
        document.body.classList.remove('bhakti-songs-body');
        if (this.cleanup) {
          this.cleanup();
        }
    }

    render() { 
        return (
            <div>
                <section className="youtube" />
                <br />
                <div className="youtube-neon-logo">
                  <img src="https://lasbibas.uxdir.com/files/youtube-neon.png" alt="YouTube Neon Logo" />
                </div> 
                <p>Bhakti_Songs Page</p>
                <div className='container'>
                    <div className='row'>
                        {playlistData.map((song, index) => (
                            <div className='col-md-6 col-lg-4 col-xl-3 mb-4' key={index}>
                                <Yt_card
                                    videoId={song.videoId}
                                    title={song.title}
                                    artist={song.artist}
                                    description={song.description}
                                />
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        );
    }
}

export default Bhakti_Songs;
