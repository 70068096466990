import React, { Component } from 'react';

class Navbar extends Component {
    state = {  } 
    render() { 
        return (
            <div>
                {/* <script src="https://cdn.jsdelivr.net/npm/bootstrap@5.3.3/dist/js/bootstrap.bundle.min.js" integrity="sha384-YvpcrYf0tY3lHB60NNkmXc5s9fDVZLESaAA55NDzOxhy9GkcIdslK1eN7N6jIeHz" crossorigin="anonymous"></script> */}
            <nav class="navbar navbar-expand-lg navbar-light bg-dark">
                <a class="navbar-brand text-white" href="#">ONBV</a>
                <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon"></span>
                </button>
                <div class="collapse navbar-collapse" id="navbarNavAltMarkup">
                    <div class="navbar-nav">
                    <a class="nav-item nav-link active text-white" href="./">Home <span class="sr-only">(current)</span></a>
                    <a class="nav-item nav-link text-white" href="./VyasJi_Book">VyasJi</a>
                    <a class="nav-item nav-link text-white" href="./Bhakti_Songs">Bhakti Songs</a>
                    <a class="nav-item nav-link text-white" href="./ShrimadBhagwadGeetaJi">BhagwadGeeta</a> 
                    <a class="nav-item nav-link text-white" href="./ScienceAndSaints">Science And Saints</a> 
                    <a class="nav-item nav-link text-white" href="./auraHomePage">Aura</a> 
                    </div>
                </div>
                </nav>
                </div>
        );
    }
}
 
export default Navbar;