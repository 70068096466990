import React, { Component } from 'react';
import './styleBhakti.css';

class VyasJi_card extends Component {
    state = {  } 
    render() { 
        const { imageSrc, name, title, facebookLink, youtubeLink, twitterLink, instagramLink } = this.props;

        return(
            <div>
<div className="our-team">
<a href={youtubeLink}>
      <div className="picture">
      <img className="img-fluid" src={imageSrc} alt="VyasJi" />
      </div>
      <div className="team-content">
        <h3 className="name">{name}</h3>
        <h4 className="title">{title}</h4>
      </div>
      </a>
      <ul className="social">
        <li><a href={facebookLink} className="fa fa-facebook" aria-hidden="true"></a></li>
        <li><a href={youtubeLink} className="fa fa-youtube" aria-hidden="true"></a></li>
        <li><a href={twitterLink} className="fa fa-twitter" aria-hidden="true"></a></li>
        <li><a href={instagramLink} className="fa fa-instagram" aria-hidden="true"></a></li>
      </ul>
    </div>
            </div>
        );
    }
}
 
export default VyasJi_card;