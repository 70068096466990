import React from 'react';
import { useParams } from 'react-router-dom';
import Jumbotron from './Jumbotron';
import cardData_temp from './cardData_temp.json';

const CardDetails = () => {
    const { cardId } = useParams(); // Accessing cardId from the URL
    // const card = cardData.find(item => item.cardId === cardId); // Finding the specific card

    const card = cardData_temp.find(item => item.cardId === cardId);
    if (!card) {
        return <div>Card not found</div>; // Handle case where card is not found
    }

    return (
        <div>
            <Jumbotron                
                title={card.title}
                detail={card.detail}
            />
        </div>
    );
}

export default CardDetails;
