import React, { Component } from 'react';
import BokehBG from './BokehBG';

class AuraHomePage extends Component {
    state = {  } 
    render() { 
        return (
            <div>
            <BokehBG/>
            </div>
        );
    }
}
 
export default AuraHomePage;    