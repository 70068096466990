import React, { Component } from 'react';

class Science_And_Saints extends Component {
    state = {  } 
    render() { 
        return (
            <div>
                <h2>Article1</h2>
                <h6>link:<a href = "https://drive.google.com/file/d/1XCLurHtU7geBhBr9FFaEnKwJ2rIrhISd/view?usp=sharing">Observation on the formation of planets and everything based on ritual analysis</a></h6>
                <h2>Article2</h2>
                <h6>link:<a href='https://drive.google.com/file/d/1kSRnXwpeZ_w_jOB3EyYzTpE92gktWVEi/view?usp=sharing'>Overview:Solar system location in the cosmos</a></h6>
            </div>
        );
    }
}
 
export default Science_And_Saints;